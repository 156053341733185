// old theme
$primary-color: #03428e;
$secondary-color: #00b6f1;

$backgournd-color: #f2fbff;

// current theme
$primary-cyan: #01B7F6;
$primary-blue: #0A428F;
$primary-darkblue: #00335E;
$primary-gray: #89B2C8;
$secondary-green: #13B480;
$secondary-light-gray: #BBD1DF;
$secondary-dark-gray: #6092AB;
$secondary-blue-black: #21333D;
$secondary-mid-cyan: #7BD3F9;
$secondary-light-cyan: #ABE1FD;

$linear-bg: linear-gradient(180deg, #00335E 16.41%, #03428E 99.97%, #03428E 99.98%, #03428E 99.99%, #03428E 100%);
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$carfin-primary: mat-palette($mat-indigo);
$carfin-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$carfin-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$carfin-theme: mat-light-theme((color: (primary: $carfin-primary,
      accent: $carfin-accent,
      warn: $carfin-warn,
    )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($carfin-theme);

/* You can add global styles to this file, and also import other style files */
@import "~ng-zorro-antd/ng-zorro-antd.min.css";
@import url("https://fonts.googleapis.com/css2?family=K2D:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import '/src/app/shared/variables/variables.scss';

// bootstrap icon 
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~bootstrap/scss/bootstrap";

// owl carousel
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

// google font
@import url('https://fonts.googleapis.com/css2?family=Lilita+One&display=swap');

*:not(.noFontFamily, font) {
  font-family: "Kanit", "Helvetica Neue", sans-serif;
}

h1:not(.noFontFamily) {
  font-family: "Kanit", "Helvetica Neue", sans-serif;
  font-size: 250% !important;
  /* 45px */
}

h2:not(.noFontFamily) {
  font-family: "Kanit", "Helvetica Neue", sans-serif;
  font-size: 200% !important;
  /* 36px */
}

h3:not(.noFontFamily) {
  font-family: "Kanit", "Helvetica Neue", sans-serif;
  font-size: 177.78% !important;
  /* 32px */
}

h4:not(.noFontFamily) {
  font-family: "Kanit", "Helvetica Neue", sans-serif;
  font-size: 162.5% !important;
  /* 26px */
}

h5:not(.noFontFamily) {
  font-family: "Kanit", "Helvetica Neue", sans-serif;
  font-size: 122% !important;
  /* 22px */
}

h6:not(.noFontFamily) {
  font-family: "Kanit", "Helvetica Neue", sans-serif;
  font-size: 100% !important;
  /* 18px */
}

.carfinn-primary-blue-text {
  color: $primary-blue
}

.carfinn-primary-cyan-text {
  color: $secondary-color
}

.carfinn-text-cyan {
  color: $primary-cyan;
}

.carfinn-primary-blue-bg {
  background: $primary-blue;
}

.carfinn-secondary-bg {
  background: $secondary-color;
}

.underline {
  text-decoration: underline;
}

.carfinn-linear-blue-bg {
  background: linear-gradient(180deg, #00335E 16.41%, #03428E 99.97%, #03428E 99.98%, #03428E 99.99%, #03428E 100%);
}

.mat-dialog-container {
  padding: 0 !important;
  overflow: hidden !important;

  .mat-dialog-content {
    padding: 0 20px;
    margin: 0 auto;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 12px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #cecece;
    }
  }

  .mat-dialog-title {
    padding: 20px;
    // color: white;
    // background-color: #0811ba;
  }

  .mat-dialog-actions {
    margin-bottom: -5px;
    padding: 10px;
  }
}

.full-width-dialog {
  max-width: 90vw !important;
}

html,
body {
  scroll-behavior: smooth;
  height: 100%;
  background-color: #f6f6f6 !important;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

// owl carousel style
.owl-theme .owl-nav [class*=owl-] {
  color: #000;
  font-size: 17px;
  margin: 5px;
  padding: 11px;
  background: #ffffff;
  display: inline-block;
  cursor: pointer;
  border-radius: 26px;
  position: absolute;
  top: 50%;
  width: 40px;
  opacity: 0.6;
  height: 40px;
}

.owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  left: 10px;
}

.owl-carousel .owl-nav .owl-next {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  right: 10px;
}

.white-background {
  background: #fff;
}

.carfinn-btn-blue {
  background: $primary-blue;
  color: #fff;
}

.carfinn-btn-outline-blue {
  background: #fff;
  color: $primary-blue;
  border: solid $primary-blue;

  &:hover {
    background: $primary-blue;
    color: #fff;
  }
}
